<template>
  <div>
    <label class="file-select">
      <div class="btn btn-rj">
        <span v-if="newFile"><i class="file-select-icon fas fa-file-alt"></i> {{ newFile.name }}</span>
        <span v-else><i class="fas fa-upload"></i> File</span>
      </div>
      <input @change="handleFileChange"
             name="fileSelect"
             id="file"
             type="file"
      />


      <button v-if="newFile" class="btn btn-success" @click="upload"><i class="fas fa-upload"></i></button>
    </label>


    <div class="files">
      <div @click="openFile(file)" :key=file.id class="file " v-for="file in files ">
        <i @click="deleteFile($event, file.fileName)" class="delete-button fas fa-trash"></i>
        <i class="file-icon fas" :class="getFontAwesomeIcon(file.extension)"></i>
        <span class="file-name">{{file.fileName}}</span>
<!--        <a :href="file.downloadLink" target="_blank"> DOWNLOAD HIER</a>-->
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/storage";
import extensions from 'font-awesome-filetypes/lib/extensions'

export default {
  name: "FileManager",
  props: ['vehicle'],
  data() {
    return {
      newFile: null,
      files: []
    }
  },
  computed: {
    getFontAwesomeIcon: () =>  (extension) => {
      return extensions[extension];
    }
  },
  methods: {
    openFile(file) {
      window.open(file.downloadLink, '_system')
    },
    async deleteFile(event, fileName) {
      event.stopPropagation();
      this.$swal.fire({
        title: 'Verwijderen',
        html: `Weet je zeker dat je <b>'${fileName}'</b> wil verwijderen? `,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Annuleer',
        confirmButtonText: 'Verwijder'
      }).then(async (result) => {
        if(result.value) {
          await firebase.storage().ref(`vehicles/${this.vehicle.id}/${fileName}`).delete();
          this.listFiles()
        }
      });

    },
    async upload() {
      let storageRef = firebase.storage().ref(`vehicles/${this.vehicle.id}`);
      let filename = this.newFile.name
      let fileRef = storageRef.child(filename);
      let uploadedFile = await fileRef.put(this.newFile);
      await this.listFiles()

      this.newFile = null
      return uploadedFile
    },
    handleFileChange(e) {
      this.newFile = e.target.files[0]
    },
    async download(link) {
      console.log(link)
    },
    async formatItems(items) {
      let formattedItems = []
      items.map(async (item) => {
        let downloadLink = await item.getDownloadURL()
        let fileName = firebase.storage().refFromURL(downloadLink).name
        let splittedFileName = fileName.split('.')
        formattedItems.push({
              downloadLink: downloadLink,
              fileName: fileName,
              extension: splittedFileName[splittedFileName.length - 1]
            }
            // All the prefixes under listRef.
            // You may call listAll() recursively on them.
        );
      })
      this.files = formattedItems
    },
    async listFiles() {
      const storageRef = firebase.storage().ref();
      const vehicleId = this.vehicle.id
      const itemFolder = `/vehicles/${vehicleId}`
      let listRef = storageRef.child(itemFolder);

      // Find all the prefixes and items.

      const {items} = await listRef.listAll()
      await this.formatItems(items)
    }
  },
  async created() {
    this.listFiles()
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/globalVariables";

.file-select {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.file-select {
  width: 100%;

  & > input[type="file"] {
    display: none;
  }

  .btn,
  span {
    display: block;
    text-align: left;
    overflow: auto;
    word-break: break-word;
    text-overflow: ellipsis;
  }

  .file-select-icon {
    margin-right: 10px;
    text-align: left;
  }
}

.file {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 15rem;
  width: 15rem;
  color: $rjRed;
  background: #fbfbfb;
  text-align: center;
  margin: 1rem;
  border: 1px solid #e0e0e0;
  padding: 1rem;
  cursor: pointer;
}

.file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.delete-button {
  text-align: right;
}

.file-icon {
  font-size: 8rem;
}

.files {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 15rem));
  grid-auto-rows: auto;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  align-items: center;
  width: 100%;
}
</style>
