<template>
  <div id="app">
    <img src="@/assets/rj_logo_white.png" class="rj-logo"/>
    <topBar style="z-index:300">
      <template slot="left">
        <a href="javascript:" v-if="$route.meta && $route.meta.canGoBack" @click="goBack()" id="sidebarCollapse">
          <i class="fal fa-arrow-left"/>
        </a>
      </template>
      <template slot="right">
        <ul v-if="getUserId">
          <li>
            <a @click="addVehicle()" href="javascript:" aria-expanded="false">
              <i class="fas fa-plus"/>
            </a>
          </li>
          <li>
            <a class="dropdown" data-toggle="dropdown" href="javascript:" aria-expanded="false">
              <i class="fad fa-user"/>
            </a>
            <div class="dropdown-menu dropdown-menu-right mt-0">
              <!--              <div class="dropdown-divider"></div>-->
              <a href="javascript:" class="dropdown-item" @click="logout()">Uitloggen</a>
            </div>
          </li>
        </ul>
      </template>
    </topBar>

    <div id="m-content">
      <transition name="fade">
        <div class="content-wrapper">
          <div id="content-container">
            <router-view/>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import {Topbar as TopBar} from 'motivate-framework-components';
import vehicleMethods from "@/mixins/vehicleMethods";
import {mapGetters} from "vuex";
import LogRocket from "logrocket";

export default {
  name: "App",
  components: {
    TopBar
  },
  mixins: [ vehicleMethods ],
  methods: {
    logout() {
      this.$FirebaseAuth.signOut();
    },
    goBack() {
      this.$router.push('/home');
    },
    async addVehicle() {
      const result = await this.create('','' );
      if (result) {
        if (result.new) {
          this.$toasted.global.successRequest({ message: 'Voertuig toegevoegd'});
        } else {
          this.$toasted.global.successRequest({ message: 'Voertuig bestaat al'});
        }
        return this.$router.push({name: 'detail', params: {vehicleId: result.id}});
      }
    },
  },
  computed: {
    ...mapGetters(['getUserId']),
  },
  created() {
    try {
      this.$FirebaseAuth.authEvent.$on('user_logged_in', (user) => {
        LogRocket.identify(user.uid, {
          name: user.displayName ? user.displayName :'',
          email: user.email,

          // Add your own custom user variables here, ie:
          // subscriptionType: 'pro'
        });
        this.$store.dispatch('vehicles/openDBChannel');

        this.$FUNCTIONS.Users('getAllUsers').then(users => {
          this.$store.commit('users/saveUsers', users);
        });
      })
    } catch (e) {
      console.error(e)
    }
  }
}
</script>
<style lang="scss">
@import "./assets/scss/main.scss";

html, body {
  height: 100%;
  max-width: 100%;
}

#content-container {
  max-width: 140rem;
  width: 100%;
}

.content-wrapper {
  width:100%;
  display: flex;
  justify-content: center;
}

.rj-logo {
  height: 40px;
  position: fixed;
  z-index: 301;
  left: calc(50% - 20px);
  top: 10px;
}


</style>
