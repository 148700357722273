import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/messaging';
import Vue from 'vue';
import router from './router/index';
import store from './store/index'
// import "firebase/performance";


export default class FirebaseAuth {
    constructor() {
        return (async () => {
            this.authEvent = new Vue();
            // All async code here
            await this.setConfig();
            // await this.refreshAuth();
            await this.checkAuth();
            this.refreshAuth();
            setInterval(() => {
                this.refreshAuth();
            }, 60000 * 30);
            return this; // when done
        })();
    }


    getConfig() {
        return this.config;
    }

    setUser(user, userData = {}) {
        if(user) {
            // user.customClaims = await this.setClaims(user.uid);
            user.userData = userData;
        }
        store.commit('setUserId', user ? user.uid : null);
        store.commit('setUserEmail', user ? user.email : null)
        return this.user = user;
    }

    getIdToken() {
        return this.idToken;
    }

    getUsers() {

    }

    getUser() {
        return this.user;
    }

    async getCurrentUser() {
        if(this.getUser) {
            return this.getUser();
        } else {
            let user = await firebase.auth().currentUser;
            return user;
        }
    }

    setClaims(claims) {
        this.claims = claims;
    }

    getClaims() {
        return this.claims;
    }

    async getUserClaims() {
        const self = this;
        if(self.claims) {
            return self.claims;
        } else {
            const user = await this.refreshAuth();
            return user ?  user.claims : null;

        }
        // let getUserById = firebase.app().functions('europe-west1').httpsCallable('callablesRootUsers_getUserById');
        // let uid = await this.getUid();
        // if(uid)
        //   return await getUserById(uid).then(function (result) {
        //     self.setClaims(result.data.customClaims);
        //     return result.data.customClaims;
        //   });
    }

    getUid() {
        return this.user ? this.user.uid : null;
    }

    async getUserData(uid) {
        let userData = await firebase.firestore().collection('users').doc(uid).get();
        return userData.data();
    }

    async updateProfile(record) {
        let self = this;
        let user = this.getUser();
        return user.updateProfile(record).then(async () => {
            let updatedProfile = await firebase.auth().currentUser;
            let userData = await self.getUserData(user.uid);
            return self.setUser(updatedProfile, userData);
        });
    }

    async setUserData( ) {

    }

    async signOut() {
        this.reset();
        return firebase.auth().signOut().then();
    }

    reset() {
        this.setUser(null);
        this.setClaims(null);
        this.idToken = null;

    }

    async login(username, password) {
        var self = this;
        return firebase.auth().signInWithEmailAndPassword(username, password).then(async res => {
            let user = res.user;
            let userData = await self.getUserData(res.user.uid);
            self.setUser(user, userData);
            await self.refreshAuth();
        });
    }


    /**
     * Hydrate this class tokens if available in localstorage
     * @returns {Promise<void>}
     */
    async checkAuth() {
        var self = this;
        this.authCheckRunning = true;
        firebase.auth().onAuthStateChanged(async function (user) {
            self.authCheckRunning = false;

            if(user) {
                self.setUser(user);
                self.getUserClaims();
                let userData = await self.getUserData(user.uid);
                self.setUser(user, userData);
                self.authEvent.$emit('user_logged_in', user);

            } else {
                self.authCheckRunning = false;

                self.setUser(null);
                self.authEvent.$emit('user_logged_out', user);

                // if(router.currentRoute.fullPath !== '/login') {
                router.push('/login');
                // }
            }
        });

    }


    async setConfig() {

        if(process.env.VUE_APP_FB_OFFLINE_PERSISTENCE === 'off') {
            return;
        } else {
            await firebase.firestore().enablePersistence().then(() => {
                firebase.firestore().settings({
                        cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
                    }
                );
            }).catch(function (err) {
                if(err.code == 'failed-precondition') {

                    // Multiple tabs open, persistence can only be enabled
                    // in one tab at a a time.
                    // ...
                } else if(err.code == 'unimplemented') {
                    // The current browser does not support all of the
                    // features required to enable persistence
                    // ...
                }
            });
        }

         Vue.prototype.$FB = firebase.firestore();

        // Vue.prototype.$FB_STORAGE = firebase.storage();
        // Vue.prototype.$FB_MESSAGING.useServiceWorker(SW_REGISTRATION);
        // Vue.prototype.$FB_MESSAGING.
        Vue.prototype.$FBClient = firebase.app();
        // Vue.prototype.$FB_UPLOADER = new FireStoreUploader(firebase.storage());
    }

    async refreshAuth() {
        const self = this;
        if(firebase.auth().currentUser)
            return await firebase.auth().currentUser.getIdTokenResult(true)
                .then(function (idToken) {
                    self.setClaims(idToken.claims);
                    self.idToken = idToken.token;
                    return idToken;
                }).catch(error => {
                    console.error(error);
                });
    }
}

