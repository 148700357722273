const firestore = {
  firestorePath: '/vehicles',
  firestoreRefType: 'collection',
  moduleName: 'vehicles',
  statePropName: 'vehicles',
  namespaced: true,
  serverChange: {
    convertTimestamps: {
      created_at: '%convertTimestamp%',
      updated_at: '%convertTimestamp%',
    },
  },
  sync: {
    debounceTimerMs: 1,
    guard: ['created_by', 'updated_by']
  }
};
export default firestore;