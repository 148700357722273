<template>
  <div class="vehicle-card" @click="openVehicle()">
    <div class="photo"
    :style="{
      'background-image': showFallBackLogo ? 'url(img/logos/placeholder.jpg)' : 'url('+ getLogo(vehicle.brand) +')'
    }">
      <img @error="imageLoadError" class="logoChecker" :src="getLogo(vehicle.brand) "/>
    </div>
    <div class="info" v-if="vehicle.processing === 'done'">
      <div v-if="vehicle.brand || vehicle.model">
        <b><span class="brand">{{ vehicle.brand }}</span></b>
        <span class="model">{{ vehicle.model }}</span>
      </div>
      <div class="title" v-else>
        {{ vehicle.title }}
      </div>
      <div class="prices">
        <div class="ask-price">
          € {{ vehicle.askPrice | formatNumber }}
        </div>
        <i class="fal fa-arrow-right"/>
        <div class="bid-price">
          € {{ vehicle.bidPrice | formatNumber }}
        </div>
      </div>
    </div>
    <div class="info failed" v-else-if="vehicle.processing === 'failed'">
      Verwerken mislukt.
    </div>
    <div v-else class="info processing">Bezig met verwerken</div>
    <div class="priority">
      <span v-if="vehicle.priority" class="priority-label">Prioriteit</span>
    </div>
    <div class="state">
      <b>{{ vehicle.status | translateState }}</b><br>
      <span class="added-timestamp"> {{ vehicle.created_at | formatDate }}</span>
    </div>
    <div class="new-message">
      <i v-if="vehicle.newChats" class="chat-badge fas fa-comment-alt-dots"></i>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "VehicleCard",
  props: ['vehicle'],
  data() {
    return {
      showFallBackLogo: false
    }
  },
  filters: {
    translateState(value) {
      if (value === 'added') {
        return 'Toegevoegd';
      } else if (value === 'negotiating') {
        return 'Aan het onderhandelen';
      } else if (value === 'bought') {
        return 'Gekocht';
      } else if (value === 'notSold') {
        return 'Niet gekocht';
      }  else if (value === 'notAvailable') {
        return 'Niet beschikbaar';
      }  else {
        return '';
      }
    },
    formatDate(value) {
      const date = moment(value);
      return date.isValid() ? date.format('DD-MM-YYYY') : '';
    }
  },
  methods: {
    imageLoadError() {
      this.showFallBackLogo = true;
    },
    openVehicle() {
      this.$emit('openVehicle', this.vehicle.id)
    },
    getLogo(brand){
      if (brand) {
        return '/img/logos/' + brand.replace(' ','-').toLowerCase() + '.png';
      } else {
        return 'img/logos/placeholder.jpg';
      }
    }
  },
  watch: {
    'vehicle.brand': {
      deep: true,
      handler() {
        this.showFallBackLogo = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/globalVariables";
.logoChecker {
  height: 1px;
  width: 1px;
  visibility: hidden;
}
.vehicle-card {
  display: grid;
  grid-template-columns: 8rem 1fr 0.1fr;
  grid-template-rows: 1fr 0.5fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  background-color: white;
  height: auto;
  padding: 1rem;
  align-self: stretch;
  cursor: pointer;
}
.processing {
  font-weight: bold;
  color: #e07400;
  align-self: center;
}

.failed {
  font-weight: bold;
  color: #e07400;
  align-self: center;
}

.photo {
  grid-area: 1 / 1 / 2 / 2;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.info {
  grid-area: 1 / 2 / 2 / 4;
}

.priority {
  grid-area: 1 / 3 / 2 / 4;
  text-align: right;
}

.state {
  grid-area: 2 / 1 / 3 / 3;
}

.new-message {
  grid-area: 2 / 2 / 3 / 4;
  font-size: 2rem;
  align-self: end;
  text-align: right;
}

.prices {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: 1fr;
  grid-column-gap: 0.5rem;
  grid-row-gap: 0px;
  align-items: center;
  word-break: keep-all;
}

.prices > * {
  font-weight: bold;
}

.ask-price {
  color: $rjRed;
}

.bid-price {
  color: #5f9a00;
}

.status-label {
  font-weight: bold;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.chat-badge {
  color: #5f9a00;
}
.priority-label {
  @extend .status-label;
  color: $rjRed;
}

.model, .brand {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

</style>