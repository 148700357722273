import _ from "lodash";

const userNames = {
    'RZQh607EdgVBMqmAcYqtIDMUF7i1': 'Mitch - Motivate',
    'CGmJvCtFewaQW3oDBU86R02p5WF2': 'Rik - Motivate',
    'KhSesLtEDxSkWrVAcC9h18tAN423': 'Ricardo',
    'UBuwgjleIScEsjOe31GzLbbvrUL2': 'Janno',
    'uQcAltwvI5O6nsuS1ZCSFH6gQ8k2': 'Sandor',
    '9VpehOzPXKV8EKzeBPWKMYMl6DG3': 'Cito',
    'f2l74YJHi2SSd3d8bSRuyXESiWw1': 'General - Motivate'
};

const getters = {
    getUsers: (state) => {
        return _.map(_.toArray(state.users), user => {
            const name = userNames[user.uid];
            user.name = name ? name : user.email;
            return user;
        });
    },
    getUserById: (state, getters, rootState, rootGetters) => (userId) => {
        const users = getters.getUsers;
        return users.length ? _.find(users, { 'uid': userId }) : {
            uid: rootGetters.getUserId,
            email: rootGetters.getUserEmail,
            name: userNames[rootGetters.getUserId] ? userNames[rootGetters.getUserId] : rootGetters.getUserEmail
        };
    },
    getUserByIdLimited: (state, getters, rootState, rootGetters) => (userId, properties) => {
        const users = getters.getUsers;
        const user = users.length ? _.find(users, { 'uid': userId }) : {
            uid: rootGetters.getUserId,
            email: rootGetters.getUserEmail,
            name: userNames[rootGetters.getUserId] ? userNames[rootGetters.getUserId] : rootGetters.getUserEmail
        };
        let limitedUser = {};
        properties.forEach(property => {
            limitedUser[property] = user[property];
        });
        return limitedUser;
    },
};
export default getters;
