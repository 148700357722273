<template>
  <i @click=shareVehicle(vehicle) class="fas fa-share-alt"></i>
</template>

<script>
import vehicleMethods from "../mixins/vehicleMethods";

export default {
  name: "VehicleShareIcon",
  props:{
    vehicle:{
      required:true
    }
  },
  mixins:[vehicleMethods]
}
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/_globalVariables.scss";

i{
  font-size:2rem;
  margin-right: 2rem;
  cursor: pointer;
  color: $rjRed;
}
</style>