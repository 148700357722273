// ~config/firebase.js
import firebase from 'firebase/app';
import 'firebase/firestore'
import Vue from "vue";

function initFirebase () {
     Vue.prototype.$FBClient = firebase.initializeApp(JSON.parse(process.env.VUE_APP_FB_CONFIG));
}


export { firebase, initFirebase }