import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import _ from 'lodash'
import moment from 'moment'
import VueSweetalert2 from 'vue-sweetalert2';
import 'motivate-framework/dist/css/motivate.css';
import './init/frameworkComponents';
// import share icon
import VehicleShareIcon from "./components/VehicleShareIcon";
Vue.component('VehicleShareIcon',VehicleShareIcon)
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
import {initFirebase} from "./init/initFirebase";
import FirebaseAuth from "./Auth";
import "jquery";
import 'bootstrap/dist/js/bootstrap.min.js';
import 'swiped-events/dist/swiped-events.min.js';
import LogRocket from 'logrocket';
import FirebaseFunctions from "@/callables/index";
import 'vue2-timepicker/dist/VueTimepicker.css'

LogRocket.init('motivate/rj-iavib');
require('./plugins/toaster');
require('./init/filters')

window._ = _
window.moment = moment
Vue.use(VueSweetalert2);
 setupAuth()

Vue.config.productionTip = false

async function setupAuth() {
    initFirebase()
    Vue.prototype.$FirebaseAuth  =  await new FirebaseAuth();
    Vue.prototype.$FUNCTIONS = new FirebaseFunctions(Vue.prototype.$FBClient);
    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app')
}
