<template>
  <div>

    <div class="dialog">
      <div class="container-login">

        <div class="wrap-login">
          <form @submit.prevent="login" class="login-form validate-form">
            <img class="login-form_logo" src="img/rj-automobielen-logo.png" alt="RJ - Logo">
            <div class="wrap-input validate-input" :class="{'alert-validate': validateUser}"
                 data-validate="Invalid username">
              <input type="text" name="username" v-model="username" autocomplete="username" id="username" class="input"
                     :class="{ 'has-val': username }"
                     @focus="validateUser = false">
              <span class="focus-input" data-placeholder="Username"/>
            </div>

            <div class="wrap-input validate-input password-input" :class="{'alert-validate': validatePassword}"
                 data-validate="Invalid credentials">
						<span class="btn-show-pass" @click="switchVisibility" :class="{active: isActive}">
                            <i class="fas" :class="isActive ? 'fa-eye-slash' : 'fa-eye'"/>
						</span>
              <input label="password" :type="passwordFieldType" name="password" id="password" v-model="password"
                     class="input"
                     :class="{ 'has-val': password }" @focus="validatePassword = false"
                     autocomplete="current-password">
              <span class="focus-input" data-placeholder="Password"/>
            </div>


            <button class="login-form_btn" id="submit" type="submit">
              Login
            </button>

            <div class="forgot-password">

            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @vuese
// @group Auth
// This view handles the login of the user.
export default {
  name: 'Login',
  components: {},
  data() {
    return {
      remember: false,
      isActive: false,
      username: null,
      password: null,
      validateUser: false,
      validatePassword: false,
      passwordFieldType: 'password',
      challenge: null

    };
  },
  computed: {},
  methods: {
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
      this.isActive = !this.isActive;
    },

    // @vuese
    // The login functions checks if both username and password are set.
    // Then uses the  FirebaseAuth module to authenticate the user
    login() {
      const self = this;

      if (!this.username || this.username.length < 4) {
        this.validateUser = true;
        this.validatePassword = true;
        return false;
      }
      // this.$store.commit('setLoading', true);
      this.$FirebaseAuth.login(this.username.replace(/\s*$/, ''), this.password).then(() => {
        self.$router.push({name: 'home'});
      }).catch(function (e) {
        return self.$swal({
          text:e.message,
          toast:true,
          position:'bottom-end',
          time:3000

        })
      })
    },
    // @vuese
    // @arg The argument passed from this event to the listener is the firebase user.
    // Registers a listener that fires when user is on the login page.
    // Then uses the  FirebaseAuth module to authenticate the user
    registerAuthEvent() {
      this.$FirebaseAuth.authEvent.$on('user_logged_in', (user) => {
        console.log(user)
      });
    }
  },

  created() {
    this.registerAuthEvent();


  }

};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/login.scss";

.dialog {
  position: absolute;
  top: -60px;
  left: 0;
  z-index: 999;
}
</style>