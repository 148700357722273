import Vue from 'vue'

export default Vue.extend({
    name: 'ValidateUrl',
    methods: {
        isValidURL(string) {
            if (!string) {
                return false;
            }
            // Decode uri string
            string = this.tryDecodeURLComponent(string);
            const res = string.match(/^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=ß]{2,256}\.[a-zß]{2,6}\b([-a-zA-Z0-9@:%_+().~#?&ß//=]*)$/g);
            return (res !== null);
        },
        getUrlFromText(text) {
            if (!text) {
                return false;
            }
            // Decode uri text
            text = this.tryDecodeURLComponent(text);
            const matches = text.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=ß]{2,256}\.[a-zß]{2,6}\b([-a-zA-Z0-9@:%_+().~#?&//=]*)/g);
            if (matches) {
                return matches[0].replace('https://link.mobile.de/?link=', '').replace('https://link.mobile.de?link=', '');
            } else {
                return text;
            }
        },
        tryDecodeURLComponent(str, maxIterations = 30, iterations = 0) {
            if (iterations >= maxIterations) {
                return str;
            } else if (typeof str === 'string' && (str.indexOf('%3D') !== -1 || str.indexOf('%25') !== -1 || str.indexOf('%C3%9F') !== -1)) {
                return this.tryDecodeURLComponent(decodeURIComponent(str), maxIterations, iterations + 1)
            }
            return decodeURIComponent(str);
        }
    }
});

