import _ from "lodash";

const getters = {
    getVehicles: (state) => {
        return _.toArray(state.vehicles);
    },
    getVehicle: (state) => (id) => {
        return _.find(state.vehicles, vehicle => {
            return vehicle.id === id
        })
    },
    getVehiclesWithReadStateAndPriceDiff: (state) => (userId) => {
        return _.toArray(_.map(state.vehicles, vehicle => {
            vehicle.newChats =  vehicle.readBy ? vehicle.readBy.indexOf(userId) === -1 : true;
            vehicle.priceDiff = (vehicle.askPrice || 0) - (vehicle.bidPrice || 0);
            return vehicle;
        }));
    }
};
export default getters;