import * as  Users from './users';

export default class FirebaseFunctions {
    functions;

    constructor(firebaseFunctions) {
        this.functions = firebaseFunctions.functions('europe-west1');
    }

    Users(funcCall, data = {}) {
        return Users[funcCall](this.functions)(data);
    }
}
