import Vue from 'vue'
import Toasted from 'vue-toasted';


Vue.use(Toasted);

let failedOptions = {
    className: 'mToasted',
    position: 'bottom-right',
    type: 'error',
    icon: 'sad-tear',
    duration: 4000,
    iconPack: 'fontawesome'
};

Vue.toasted.register('failedRequest',
    (payload) => {
        if(!payload.message) {
            return 'Oops.. Something Went Wrong..'
        }
        return 'Oops.. ' + payload.message;
    },
    failedOptions
);

let warnOptions = {
    className: 'mToasted',
    position: 'bottom-right',
    type: 'warn',
    icon: 'warn',
    duration: 4000,
    iconPack: 'fontawesome'
};

Vue.toasted.register('warnRequest',
    (payload) => {
        if(!payload.message) {
            return 'Pay attention!'
        }
        return payload.message;
    },
    warnOptions
);

let successOptions = {
    className: 'mToasted',
    position: 'bottom-right',
    type: 'success',
    duration: 3000,
    icon: 'smile',
    iconPack: 'fontawesome'
};

Vue.toasted.register('successRequest',
    (payload) => {
        if(!payload.message) {
            return 'Succesvol bijgewerkt'
        }
        return payload.message;
    },
    successOptions
);

let infoOptions = {
    className: 'mToasted',
    position: 'bottom-right',
    type: 'info',
    duration: 3000,
    icon: {name: 'lightbulb-on'},
    iconPack: 'fontawesome',
    singleton: true
};

Vue.toasted.register('info',
    (payload) => {
        if(!payload.message) {
            return ' '
        }
        return payload.message;
    },
    infoOptions
);