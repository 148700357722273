<template>
  <div>
    <div :class="message.authorUser.id === 'system' ? 'speech-bubble-left-system' : (isMe() ? 'speech-bubble-right' : 'speech-bubble-left')">
      <div class="author">{{ isMe() ? 'Ik' : message.authorUser.name }}:</div>
      <div v-if="unread" class="unread">Nieuw</div>
      <div class="message">{{ message.message }}</div>
    </div>
    <div :class="isMe() ? 'timestamp-right' : 'time-stamp-left'" class="timestamp">{{ message.created_at | formatDate }}</div>
  </div>

</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";

export default {
  name: "SpeechBubble",
  props: ['message'],
  data() {
    return {
      unread: false
    }
  },
  filters: {
    formatDate(value) {
      return moment(value).format('HH:mm, DD-MM-YYYY')
    }
  },
  computed: {
    ...mapGetters(['getUserId']),
  },
  methods: {
    isMe() {
      return this.getUserId === this.message.authorUser.id;
    },
    setMessageToRead() {
      this.unread = true;
      this.message.readBy.push(this.getUserId);
      this.$store.dispatch('chat/patch', this.message);
    }
  },
  created() {
    if (this.message.readBy.indexOf(this.getUserId) === -1) {
      this.setMessageToRead();
    }
  },
  watch: {
    'message.readBy': {
      deep: true,
      handler() {
        if (this.message.readBy.indexOf(this.getUserId) === -1) {
          this.setMessageToRead();
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.speech-bubble {
  position: relative;
  border-radius: .4em;
  min-height: 8rem;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, auto);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  height: fit-content;
}


.speech-bubble-after {
  content: '';
  position: absolute;
  top: 20%;
  width: 0;
  height: 0;
  border: 1.5rem solid transparent;
  border-top: 0;
  margin-top: -0.75rem;
}

.speech-bubble-left-system {
  @extend .speech-bubble;
  background: #d2d2d2;
  color: black;
}
.speech-bubble-left-system:after {
  @extend .speech-bubble-after;
  left: 0;
  border-right-color: #d2d2d2;
  border-left: 0;
  margin-left: -1.5rem;
}
.speech-bubble-left {
  @extend .speech-bubble;
  background: #d8dfff;
  color: black;
}

.speech-bubble-left:after {
  @extend .speech-bubble-after;
  left: 0;
  border-right-color: #d8dfff;
  border-left: 0;
  margin-left: -1.5rem;
}



.speech-bubble-right {
  @extend .speech-bubble;
  background: #a8d7c6;
  color: black;
}
.speech-bubble-right:after {
  @extend .speech-bubble-after;
  right: 0;
  border-left-color: #a8d7c6;
  border-right: 0;
  margin-right: -1.5rem;
}

.author {
  font-weight: bold;
}

.message {
  grid-area: 2 / 1 / 3 / 3;
}

.timestamp {
  font-style: italic;
  font-size: 1.2rem;
  margin-top: 0.2rem;
}

.timestamp-left {
  text-align: left;
}

.timestamp-right {
  text-align: right;
}
.unread {
  justify-self: end;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: underline;
}
</style>