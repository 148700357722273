<template>
  <div>
    <div class="vehicles-filter">
      <div class="filter-sort-buttons">
        <!--        <button class="sort-button btn btn-secondary dropdown" data-toggle="dropdown" href="javascript:"-->
        <!--                aria-expanded="false">-->
        <!--          <i class="far mr-3 fa-sort"/> Sorteren-->
        <!--        </button>-->
        <!--        <div class="dropdown-menu dropdown-menu-right mt-0" @click="$event.stopPropagation()">-->
        <!--          <h5>Sorteer op</h5>-->
        <div class="input-group">
          <div class="input-group-prepend" @click="setSortOrder(true)">
              <span class="input-group-text sort-order">
                <i v-if="activeSortOrder[0] === 'desc'" class="fas fa-sort-alpha-down-alt"></i>
                <i v-if="activeSortOrder[0] === 'asc'" class="fas fa-sort-alpha-up-alt"></i>
              </span>
          </div>
          <select @click="$event.stopPropagation()"
                  @change="setSortOrder(false)"
                  name="type-of-answer" id="status" class="form-control"
                  v-model="activeSortProperty">
            <option v-for="(sortOption, index) in sortOptions" :key="index" :value="sortOption.key">
              {{ sortOption.label }}
            </option>
          </select>
        </div>
        <!--        </div>-->
        <button class="filter-button btn btn-secondary" @click="filterOpen = !filterOpen">
          <i class="far mr-3"
             :class="{'fa-filter': !filterOpen, 'fa-times': filterOpen }"/>
          {{ !filterOpen ? 'Open filter' : 'Sluit filter' }}
        </button>
      </div>
      <div v-if="filterOpen" class="filter-options">
        <div class="filter-header">
          <h5>Filter opties</h5>
          <button class="form-control btn btn-outline-secondary"
                  @click="resetFilters()"
                  aria-expanded="false"
                  type="button">
            <span>Reset filters</span>
          </button>

        </div>

        <div class="filter-inputs">

          <div class="dropdown form-group">
            <label for="filter-brand">Merk</label>
            <button id="filter-brand"
                    class="form-control btn btn-outline-filter  dropdown" data-toggle="dropdown" href="javascript:"
                    aria-expanded="false"
                    type="button">
              <span>Gefilterde merken ({{ activeFilters.brand.values.length }})</span>
            </button>
            <div class="dropdown-menu">
              <div class="option-wrapper">
                <div class="custom-control custom-checkbox"
                     :key="brand"
                     @click="$event.stopPropagation()"
                     v-for="brand in getBrands">
                  <input type="checkbox" :id="'checkbox-brand-' + brand"
                         :value="brand"
                         class="custom-control-input" v-model="activeFilters.brand.values">
                  <label class="custom-control-label"
                         :for="'checkbox-brand-' + brand">{{ brand ? brand : 'Geen merk' }}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="dropdown form-group">
            <label for="filter-model">Model</label>
            <button id="filter-model"
                    class="form-control btn btn-outline-filter  dropdown" data-toggle="dropdown" href="javascript:"
                    aria-expanded="false"
                    type="button">
              <span>Gefilterde modellen ({{ activeFilters.model.values.length }})</span>
            </button>
            <div class="dropdown-menu">
              <div class="option-wrapper">
                <div class="custom-control custom-checkbox"
                     :key="model"
                     @click="$event.stopPropagation()"
                     v-for="model in getModels">
                  <input type="checkbox" :id="'checkbox-model-' + model"
                         :value="model"
                         class="custom-control-input" v-model="activeFilters.model.values">
                  <label class="custom-control-label"
                         :for="'checkbox-model-' + model">{{ model ? model : 'Geen model' }}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="dropdown form-group">
            <label for="filter-status">Status</label>
            <button id="filter-status"
                    class="form-control btn btn-outline-filter  dropdown" data-toggle="dropdown" href="javascript:"
                    aria-expanded="false"
                    type="button">
              <span>Gefilterde statussen ({{ activeFilters.status.values.length }})</span>
            </button>
            <div class="dropdown-menu">
              <div class="option-wrapper">
                <div class="custom-control custom-checkbox"
                     :key="status.value"
                     @click="$event.stopPropagation()"
                     v-for="status in staticFilters.status">
                  <input type="checkbox" :id="'checkbox-status-' + status.value"
                         :value="status.value"
                         class="custom-control-input" v-model="activeFilters.status.values">
                  <label class="custom-control-label"
                         :for="'checkbox-status-' + status.value">{{ status.label }}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="dropdown form-group">
            <label for="filter-priority">Prioriteit</label>
            <button id="filter-priority"
                    class="form-control btn btn-outline-filter  dropdown" data-toggle="dropdown" href="javascript:"
                    aria-expanded="false"
                    type="button">
              <span>{{ activeFilters.priority.values.length ? 'Filter staat aan' : 'Niet gefilterd' }}</span>
            </button>
            <div class="dropdown-menu">
              <div class="option-wrapper">
                <div class="custom-control custom-checkbox"
                     :key="priority.value"
                     @click="$event.stopPropagation()"
                     v-for="priority in staticFilters.priority">
                  <input type="checkbox" :id="'checkbox-priority-' + priority.value"
                         :value="priority.value"
                         class="custom-control-input" v-model="activeFilters.priority.values">
                  <label class="custom-control-label"
                         :for="'checkbox-priority-' + priority.value">{{ priority.label }}</label>
                </div>
              </div>
            </div>
          </div>

          <div class="dropdown form-group">
            <label for="filter-newChat">Nieuwe berichten</label>
            <button id="filter-newChat"
                    class="form-control btn btn-outline-filter  dropdown" data-toggle="dropdown" href="javascript:"
                    aria-expanded="false"
                    type="button">
              <span>{{ activeFilters.newChats.values.length ? 'Filter staat aan' : 'Niet gefilterd' }}</span>
            </button>
            <div class="dropdown-menu">
              <div class="option-wrapper">
                <div class="custom-control custom-checkbox"
                     :key="newChat.value"
                     @click="$event.stopPropagation()"
                     v-for="newChat in staticFilters.newChats">
                  <input type="checkbox" :id="'checkbox-newChat-' + newChat.value"
                         :value="newChat.value"
                         class="custom-control-input" v-model="activeFilters.newChats.values">
                  <label class="custom-control-label"
                         :for="'checkbox-newChat-' + newChat.value">{{ newChat.label }}</label>
                </div>
              </div>
            </div>
          </div>


          <div class="form-group">
            <label for="assigned-to">Gebruikers</label>
            <select id="assigned-to" class="form-control"
                    v-model="activeFilters.assignedTo">
              <option :value="true">Mijn auto's</option>
              <option :value="false">Alle auto's</option>
            </select>
          </div>


        </div>
      </div>
    </div>
    <div class="vehicles">
      <VehicleCard :vehicle="vehicle" @openVehicle="openVehicle($event)" v-bind:key="vehicle.id"
                   v-for="vehicle in getFilteredAndSortedVehicles"></VehicleCard>
      <div v-if="!getFilteredAndSortedVehicles.length" class="no-vehicles-found">
        Geen auto's gevonden.
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import VehicleCard from "@/components/VehicleCard";
import * as _ from 'lodash';

export default {
  name: "Home",
  components: {
    VehicleCard
  },
  data() {
    return {
      filterVersion: '1.0',
      filterOpen: false,
      activeSortProperty: 'priority',
      activeSortOrder: ['desc'],
      activeFilters: {
        brand: {
          values: []
        },
        model: {
          values: []
        },
        status: {
          values: []
        },
        priority: {
          values: []
        },
        newChats: {
          values: []
        },
        assignedTo: false
      },
      sortOptions: {
        'priority': {
          key: 'priority',
          label: 'Prioriteit',
          properties: ['priority'],
          order: ['desc'],
        },
        'priceDiff': {
          key: 'priceDiff',
          label: 'Verschil tussen vraag en biedprijs',
          properties: ['priceDiff'],
          order: ['desc'],
        },
        'newChats': {
          key: 'newChats',
          label: 'Ongelezen berichten',
          properties: ['newChats'],
          order: ['desc'],
        },
        'askPrice': {
          key: 'askPrice',
          label: 'Vraagprijs',
          properties: ['askPrice'],
          order: ['asc'],
        },
        'bidPrice': {
          key: 'bidPrice',
          label: 'Richtprijs inkoop',
          properties: ['bidPrice'],
          order: ['asc'],
        },
        'created_at': {
          key: 'created_at',
          label: 'Toevoegdatum',
          properties: ['created_at'],
          order: ['desc'],
        },
        'assignedTo': {
          key: 'assignedTo',
          label: 'Toegewezen aan',
          properties: ['assignedTo'],
          order: ['asc']
        }
      },
      staticFilters: {
        status: [
          {
            label: 'Toegevoegd',
            value: 'added'
          },
          {
            label: 'Aan het onderhandelen',
            value: 'negotiating'
          },
          {
            label: 'Gekocht',
            value: 'bought'
          },
          {
            label: 'Niet gekocht',
            value: 'notSold'
          },
          {
            label: 'Niet beschikbaar',
            value: 'notAvailable'
          }
        ],
        priority: [
          {
            label: 'Prioriteit',
            value: true
          },
          {
            label: 'Geen prioriteit',
            value: false
          }
        ],
        newChats: [
          {
            label: 'Nieuwe berichten',
            value: true
          },
          {
            label: 'Geen nieuwe berichten',
            value: false
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['getUserId']),
    ...mapGetters('users', ['getUsers']),
    ...mapGetters('vehicles', ['getVehicles', 'getVehiclesWithReadStateAndPriceDiff']),
    getBrands() {
      return _.uniq(_.map(this.getVehicles, 'brand')).sort();
    },
    getModels() {
      return _.uniq(_.map(this.getVehicles, 'model')).sort();
    },
    getFilteredAndSortedVehicles() {
      let filteredAndSortedVehicles = this.getVehiclesWithReadStateAndPriceDiff(this.getUserId);
      if (this.activeFilters.priority.values.length) {
        filteredAndSortedVehicles = _.filter(filteredAndSortedVehicles, vehicle => {
          return this.activeFilters.priority.values.indexOf(vehicle.priority) > -1;
        })
      }
      if (this.activeFilters.newChats.values.length) {
        filteredAndSortedVehicles = _.filter(filteredAndSortedVehicles, vehicle => {
          return this.activeFilters.newChats.values.indexOf(vehicle.newChats) > -1;
        })
      }
      if (this.activeFilters.status.values.length) {
        filteredAndSortedVehicles = _.filter(filteredAndSortedVehicles, vehicle => {
          return this.activeFilters.status.values.indexOf(vehicle.status) > -1;
        })
      }
      if (this.activeFilters.model.values.length) {
        filteredAndSortedVehicles = _.filter(filteredAndSortedVehicles, vehicle => {
          return this.activeFilters.model.values.indexOf(vehicle.model) > -1;
        })
      }
      if (this.activeFilters.brand.values.length) {
        filteredAndSortedVehicles = _.filter(filteredAndSortedVehicles, vehicle => {
          return this.activeFilters.brand.values.indexOf(vehicle.brand) > -1;
        })
      }
      if (this.activeFilters.assignedTo) {
        filteredAndSortedVehicles = _.filter(filteredAndSortedVehicles, vehicle => {
          return vehicle.assignedTo === this.getUserId;
        })
      }
      return _.orderBy(filteredAndSortedVehicles, this.sortOptions[this.activeSortProperty].properties, this.activeSortOrder);
    },
  },
  methods: {
    resetFilters() {
      this.activeFilters = {
        brand: {
          values: []
        },
        model: {
          values: []
        },
        status: {
          values: []
        },
        priority: {
          values: []
        },
        newChats: {
          values: []
        },
        assignedTo: false
      };
    },
    openVehicle(vehicleId) {
      return this.$router.push({name: 'detail', params: {vehicleId}});
    },
    // Sets the order and inverts if the active sort is re-chosen
    setSortOrder(invertOrder) {
      if (invertOrder) {
        this.activeSortOrder = _.map(this.activeSortOrder, order => {
          if (order === 'desc') {
            return 'asc';
          } else {
            return 'desc';
          }
        })
      } else {
        this.activeSortOrder = this.sortOptions[this.activeSortProperty].order;
      }
    }
  },
  created() {
    const filterVersion = localStorage.getItem('filterVersion');
    if (filterVersion !== this.filterVersion) {
      localStorage.removeItem('savedFilters');
      localStorage.removeItem('activeSortOrder');
      localStorage.removeItem('activeSortProperty');
    }
    localStorage.setItem('filterVersion', this.filterVersion);
    const savedFilters = localStorage.getItem('savedFilters');
    const savedSortOrder = localStorage.getItem('activeSortOrder');
    const savedSortProperty = localStorage.getItem('activeSortProperty');
    if (savedFilters) {
      this.activeFilters = JSON.parse(savedFilters);
    }
    if (savedSortOrder) {
      this.activeSortOrder = JSON.parse(savedSortOrder);
    }
    if (savedSortProperty) {
      this.activeSortProperty = JSON.parse(savedSortProperty);
    }
  },
  watch: {
    'activeSortOrder': {
      deep: true,
      handler() {
        localStorage.setItem('activeSortOrder', JSON.stringify(this.activeSortOrder));
      }
    },
    'activeSortProperty': {
      deep: true,
      handler() {
        localStorage.setItem('activeSortProperty', JSON.stringify(this.activeSortProperty));
      }
    },
    'activeFilters': {
      deep: true,
      handler() {
        localStorage.setItem('savedFilters', JSON.stringify(this.activeFilters));
      }
    },
    'getBrands': {
      deep: true,
      handler(newBrands, oldBrands) {
        const removedBrands = _.difference(oldBrands, newBrands);
        this.activeFilters.brand.values = _.filter(brand => {
          return removedBrands.indexOf(brand) === -1;
        });
      }
    },
    'getModels': {
      deep: true,
      handler(newModels, oldModels) {
        const removedModels = _.difference(oldModels, newModels);
        this.activeFilters.model.values = _.filter(model => {
          return removedModels.indexOf(model) === -1;
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/globalVariables";

.vehicles {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  grid-auto-rows: auto;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  align-items: center;
  width: 100%;
}

.filter-inputs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
  grid-auto-rows: auto;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  align-items: center;
  width: 100%;
}

.vehicles-filter {
  margin-bottom: 1rem;
}

.filter-sort-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  align-items: center;
  width: 100%;
}

.filter-button {
  justify-self: end;
  width: 100%;
}

.sort-button {
  justify-self: start;
}

.vehicles-filter {
  display: grid;
  justify-items: center;
}

.filter-options {
  background: white;
  padding: 1rem;
  width: 100%;
  margin-top: 1rem;
}

.filter-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 0px;
  align-items: center;
  margin-bottom: 1rem;
}

.no-vehicles-found {
  background: white;
  padding: 1rem;
}

.custom-control {
  margin-top: 1rem;
}

.filter-inputs > .dropdown > .dropdown-menu {
  width: fit-content;
}

.option-wrapper {
  width: max-content;
}

.sort-order {
  font-size: 2rem;
  font-weight: normal;
  cursor: pointer;
  background-color: $rjRed;
}
</style>