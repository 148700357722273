import Vue from 'vue'
import {
    // DataTable,
    mAutocomplete,
    Modal, Portlet} from 'motivate-framework-components'


Vue.component('portlet', Portlet);
Vue.component('modal', Modal);
Vue.component('mAutocomplete', mAutocomplete);

// Vue.component('dataTables', DataTable);
// Vue.component('backButton', BackButton);
// Vue.component('createButton', CreateButton);
// Vue.component('requiredNotice', RequiredNotice);
