<template>
  <div>
    <pre>
    </pre>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import vehicleMethods from '../mixins/vehicleMethods';

export default {
  name: "Share",
  data() {
    return {
      url: null,
      title: "Onbekend",

    }
  },
  mixins: [vehicleMethods],
  computed: {
    ...mapGetters('vehicles', ['getVehicles'])
  },
  methods: {
    validURL(str) {
      const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?') // query string
      // '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
      return !!pattern.test(str);
    },
    async addNewVehicle() {
      const result = await this.create(this.title, this.url);
      if (!result) {
        return this.$router.push('home');
      } else {
        if (result.new) {
          this.$toasted.global.successRequest({message: 'Voertuig toegevoegd'});
        } else {
          this.$toasted.global.successRequest({message: 'Voertuig bestaat al'});
        }
        return this.$router.push({name: 'detail', params: {vehicleId: result.id}});
      }
    }
  },
  watch: {
    url() {
      this.addNewVehicle()
    },
    '$route': function () {
      console.log('urlchanged', this.url)
    }
  },
  updated() {
    console.log('updated')
  },
  beforeRouteUpdate(to, from, next) {
    next()
  },
  created() {
    // window.addEventListener('DOMContentLoaded', () => {
    const parsedUrl = new URL(window.location);

    // this.isValidURL(val.url))
    // const urlInText = this.getUrlFromText(val.url);

    if (this.isValidURL(parsedUrl.searchParams.get('url'))) {
      this.url = parsedUrl.searchParams.get('url')
    } else if (this.isValidURL(this.getUrlFromText(parsedUrl.searchParams.get('url')))) {
      const urlFromText = this.getUrlFromText(parsedUrl.searchParams.get('url'));
      this.url = urlFromText;
    } else if (this.isValidURL(parsedUrl.searchParams.get('text'))) {
      this.url = parsedUrl.searchParams.get('text')
    } else if  (this.isValidURL(this.getUrlFromText(parsedUrl.searchParams.get('text')))) {
      this.url = this.getUrlFromText(parsedUrl.searchParams.get('text'));
    } else if (this.isValidURL(parsedUrl.searchParams.get('title'))) {
      this.url = parsedUrl.searchParams.get('title')
    } else if  (this.isValidURL(this.getUrlFromText(parsedUrl.searchParams.get('title')))) {
      this.url = this.getUrlFromText(parsedUrl.searchParams.get('title'));
    } else {
      this.$toasted.global.failedRequest({message: 'Geen geldige url gevonden.'});
      this.$router.push({name: 'home'});
    }
    console.log("URL", this.url);
    if (parsedUrl.searchParams.get('title')) {
      this.title = parsedUrl.searchParams.get('title')

    }
  }
}
</script>

<style>
.swal-priority > label {
  margin-right: 1rem;
}

.swal-priority-checkbox {
  display: grid;
  grid-template-columns: 0.4fr 0.5fr;
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 0px;
  align-items: center;
  margin-top: 2rem;
}

.swal-priority-checkbox > input {
  height: 2rem;
  width: 2rem;
  text-align: right;
  justify-self: end;
}

.swal-priority-checkbox > label {
  text-align: left;
  margin-bottom: 0px;
}
</style>