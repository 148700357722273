<template>
<div>
  <div class="form">

    <div class="form-group">
      <label for="title">Titel</label>
      <input :disabled="!canEdit" id="title"
             type="text"
             class="form-control"
             v-model="editingVehicle.title"/>
    </div>

    <div class="form-group">
      <label for="brand">Merk</label>
      <input :disabled="!canEdit" id="brand"
             type="text"
             class="form-control"
             v-model="editingVehicle.brand"/>
    </div>


    <div class="form-group">
      <label for="model">Model</label>
      <input :disabled="!canEdit" id="model"
             type="text"
             class="form-control"
             v-model="editingVehicle.model"/>
    </div>

    <div class="form-group">
      <label for="buildyear">Bouwjaar</label>
      <input :disabled="!canEdit" id="buildyear"
             type="text"
             class="form-control"
             v-model="editingVehicle.buildyear"/>
    </div>

    <div class="form-group">
      <label for="askPrice">Vraagprijs</label>
      <input :disabled="!canEdit" id="askPrice"
             type="number"
             class="form-control"
             v-model="editingVehicle.askPrice"/>
    </div>


    <div class="form-group">
      <label for="bidPrice">Biedprijs</label>
      <input :disabled="!canEdit" id="bidPrice"
             type="number"
             class="form-control"
             v-model="editingVehicle.bidPrice"/>
    </div>

    <div class="form-group">
      <label for="status">Status</label>
      <select :disabled="!canEdit"
              name="type-of-answer" id="status" class="form-control"
              v-model="editingVehicle.status">
        <option value="added">Toegevoegd</option>
        <option value="negotiating">Onderhandelen</option>
        <option value="bought">Gekocht</option>
        <option value="notSold">Niet gekocht</option>
        <option value="notAvailable">Niet beschikbaar</option>
      </select>
    </div>

    <div class="form-group">
      <label for="assignedTo">Toegewezen aan</label>
      <select :disabled="!canEdit"
              v-if="getUsers.length"
              id="assignedTo" class="form-control"
              v-model="editingVehicle.assignedTo">
        <template v-for="user in getUsers">
          <option :key="user.uid" v-if="user.name.indexOf('Motivate') === -1 || editingVehicle.assignedTo === user.uid" :value="user.uid">{{  user.name  }}</option>
        </template>
      </select>
      <select :disabled="true"
              v-else
              id="assignedTo" class="form-control">
        <option selected>Gebruikers laden...</option>
      </select>
    </div>

    <div class="custom-control custom-checkbox">
      <input :disabled="!canEdit" v-model="editingVehicle.priority"
             type="checkbox" id="priority"
             class="custom-control-input"
      >
      <label class="custom-control-label" for="priority">
        <span>Heeft prioriteit</span>
      </label>
    </div>

    <div class="form-group">
      <label for="color">Kleur</label>
      <input :disabled="!canEdit" id="color"
             type="text"
             class="form-control"
             v-model="editingVehicle.color"/>
    </div>

    <div class="form-group">
      <label for="colorType">Kleurtype</label>
      <input :disabled="!canEdit" id="colorType"
             type="text"
             class="form-control"
             v-model="editingVehicle.colorType"/>
    </div>

    <div class="form-group">
      <label for="mileage">Kilometerstand</label>
      <input :disabled="!canEdit" id="mileage"
             type="number"
             class="form-control"
             v-model="editingVehicle.mileage"/>
    </div>

    <div class="form-group">
      <label for="city">Stad</label>
      <input :disabled="!canEdit" id="city"
             type="text"
             class="form-control"
             v-model="editingVehicle.address.city"/>
    </div>

    <div class="form-group">
      <label for="street">Straat</label>
      <input :disabled="!canEdit" id="street"
             type="text"
             class="form-control"
             v-model="editingVehicle.address.street"/>
    </div>

    <div class="form-group">
      <label for="engineCapacity">Motorinhoud</label>
      <input :disabled="!canEdit" id="engineCapacity"
             type="text"
             class="form-control"
             v-model="editingVehicle.engineCapacity"/>
    </div>

    <div class="form-group">
      <label for="tag">Tag</label>
      <input :disabled="!canEdit" id="tag"
             type="text"
             class="form-control"
             v-model="editingVehicle.tag"/>
    </div>

<div class="website-input">
  <label for="url">Website</label>
  <div class="input-group">
    <input :disabled="!canEdit" id="url"
           type="text"
           class="form-control"
           v-model="editingVehicle.url"/>
    <div class="input-group-append">
        <span @click="openWebsite" class="input-group-text open-website" :class="!editingVehicle.url || (editingVehicle.url && !this.isValidURL(editingVehicle.url)) ? 'disabled' : ''">
          <i class="fas fa-external-link"></i>
        </span>
    </div>
  </div>
  <span class="error" v-if="editingVehicle.url && !this.isValidURL(editingVehicle.url)">Geen geldige url</span>
</div>

  </div>
  <button :disabled="editingVehicle.url && !this.isValidURL(editingVehicle.url)" class="save-button btn btn-secondary" @click="save()">
    <i class="fas fa-save"></i> Opslaan
  </button>
</div>
</template>

<script>
import * as _ from 'lodash';
import validateUrl from "@/mixins/validateUrl";
import {mapGetters} from "vuex";

export default {
  name: "Properties",
  props: ['vehicle'],
  mixins: [validateUrl],
  data() {
    return {
      editingVehicle: null
    }
  },
  methods: {
    async save() {
      try {
        if (this.editingVehicle.processing === 'failed') {
          this.editingVehicle.processing = 'done';
        }
        await this.$store.dispatch('vehicles/set', this.editingVehicle);
        this.$toasted.global.successRequest();
      } catch (e) {
        this.$toasted.global.failedRequest();
      }
    },
    openWebsite() {
      if (this.editingVehicle.url && this.isValidURL(this.editingVehicle.url)){
        window.open(this.editingVehicle.url, '_system');
      }
    }
  },
  computed: {
    ...mapGetters('users',['getUsers']),
    canEdit() {
      return this.vehicle.processing === 'done' || this.vehicle.processing === 'failed';
    }
  },
  created() {
    this.editingVehicle = _.cloneDeep(this.vehicle);
  },
  watch: {
    'vehicle.processing': {
      deep: true,
      handler() {
        this.editingVehicle = _.cloneDeep(this.vehicle);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/globalVariables";

.form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  grid-auto-rows: auto;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  align-items: start;
  width: 100%;
}

.save-button {
  margin-top: 1rem;
  width: 100%;
}
.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.custom-checkbox {
  align-self: center;
}
.error {
  color: red;
  font-size: 1.2rem;
  font-weight: bold;
}

.open-website {
  cursor: pointer;
}

.website-input {
  margin-bottom: 1.6rem;
}
</style>