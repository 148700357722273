<template>
  <div>
    <div id="chats">
      <SpeechBubble :class="getClass(message.authorUser.id)" :key="message.id" :message="message"
                    v-for="message in getMessages"/>
    </div>
    <div v-if="!getMessages.length">Nog geen berichten</div>
    <div class="send-new-message">
      <div class="form-group">
        <label for="exampleFormControlTextarea1">Nieuw bericht:</label>
        <textarea class="form-control" v-model="message.message" id="exampleFormControlTextarea1" rows="3"></textarea>
      </div>
      <button :disabled="!message.message" class="filter-button btn btn-secondary" @click="sendMessage">
        <i class="fas fa-paper-plane"/>
      </button>
    </div>
  </div>
</template>

<script>
import SpeechBubble from '@/components/SpeechBubble';
import {mapGetters} from "vuex";
import $ from 'jquery';

export default {
  name: "Properties",
  props: ['vehicle'],
  components: {
    SpeechBubble
  },
  data() {
    return {
      message: {}
    }
  },
  computed: {
    ...mapGetters(['getUserId']),
    ...mapGetters('chat', ['getMessages']),
    ...mapGetters('users', ['getUserById']),
  },
  methods: {
    async initPage() {
      this.createNewMessage();
      try {
        await this.$store.dispatch('chat/closeDBChannel', {clearModule: true});
      } catch (e) {
        console.warn('Chats channel already closed');
      }
      await this.$store.dispatch('chat/openDBChannel', {where: [['vehicleId', '==', this.vehicle.id]]});
      this.scrollToBottom(false);
      this.setChatToRead();
    },
    getClass(authorUserId) {
      if (authorUserId === this.getUserId) {
        return 'chat-me';
      } else {
        return 'chat-other';
      }
    },
    scrollToBottom(animated) {
      const div = document.getElementById('chats');
      $('#chats').animate({
        scrollTop: div.scrollHeight - div.clientHeight
      }, animated ? 500 : 1);
    },
    setChatToRead() {
      if (this.vehicle.readBy.indexOf(this.getUserId) === -1) {
        this.vehicle.readBy.push(this.getUserId);
        this.$store.dispatch('vehicles/patch', this.vehicle);
      }
    },
    createNewMessage() {
      this.message = {
        readBy: [this.getUserId],
        vehicleId: this.vehicle.id,
        message: '',
        authorUser: {
          id: this.getUserById(this.getUserId).uid,
          name: this.getUserById(this.getUserId).name
        }
      };
    },
    async sendMessage() {
      try {
        await this.$store.dispatch('chat/insert', this.message);
        this.$toasted.global.successRequest({message: 'Bericht verstuurd'});
        this.createNewMessage();
      } catch (e) {
        console.error(e)
        this.$toasted.global.failedRequest();
      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    await this.$store.dispatch('chat/closeDBChannel', {clearModule: true});
    next()
  },
  async created() {
    this.initPage();
  },
  watch: {
    'getMessages': {
      deep: true,
      handler() {
        this.scrollToBottom(true);
        this.setChatToRead();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/globalVariables";

.chat-other {
  grid-column-start: 1;
  grid-column-end: 3;
}

.chat-me {
  grid-column-start: 2;
  grid-column-end: 4;
}

.load-more {
  text-align: center;
  padding-bottom: 1rem;
  margin-right: -2rem;
  text-decoration: underline;
  color: $rjRed;
  margin-bottom: 2rem;
  cursor: pointer;
}


#chats {
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr;
  grid-auto-rows: auto;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  height: calc(100vh - 47rem);
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 2rem;
  padding-left: 2rem;
}

.send-new-message > button {
  margin-top: 3rem;
  margin-bottom: 1.6rem;
}

.send-new-message {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
}
</style>