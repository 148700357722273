import validateUrl from "@/mixins/validateUrl";

export default {

    name: 'createVehicle',
    mixins: [validateUrl],
    methods: {
        async create(title, url) {
            // If shared from another app we can already check if the url exists
            const existingDocumentId = url ? await this.getExistingDocumentId(url) : null;
            console.log('create url', url);
            if (existingDocumentId) {
                return {new: false, id: existingDocumentId};
            } else {
                const {value: val} = await this.$swal({
                    title: 'Nieuw voertuig: ' + (title ? title : ''),
                    html: ` <div class="swal-input-new-car form-group">
                                <div style="display: ${url ? 'none' : 'inherit'}">
                                    <label for="swal-url">Url</label>
                                    <input value="${url}" class="form-control" id="swal-url" type=text>
                                </div>
                                <div>
                                    <label for="swal-bidPrice">Richtrijs inkoop</label>
                                    <input class="form-control" id="swal-bidPrice" type=number>
                                </div>
                                <div class="swal-priority-checkbox">
                                    <input id="swal-priority" type=checkbox>
                                    <label for="swal-priority">Heeft prioriteit</label>
                                </div>
                            </div>
                        `,
                    focusConfirm: false,
                    preConfirm: () => {
                        return {
                            bidPrice: parseInt(document.getElementById('swal-bidPrice').value),
                            priority: document.getElementById('swal-priority').checked,
                            url: url ? url : document.getElementById('swal-url').value
                        }

                    }
                });
                if (val) {
                    if (!val.url || isNaN(val.bidPrice)) {
                        return this.create(title, url);
                    } else if (!this.isValidURL(val.url)) {
                        console.log("val.url" , val.url)
                        const urlInText = this.getUrlFromText(val.url);
                        console.log("url in text", urlInText)
                        if (this.isValidURL(urlInText)) {
                            val.url = urlInText;
                        } else {
                            return this.create(title, url);
                        }
                    }
                    // If manual add: the check can only take place after the form is filled
                    const existingDocumentId = val.url ? await this.getExistingDocumentId(val.url) : null;
                    if (existingDocumentId) {
                        return {new: false, id: existingDocumentId};
                    } else {
                        const result = await this.$store.dispatch('vehicles/insert', {
                            url: val.url,
                            processing: "unprocessed",
                            address: {
                              city: '',
                              street: ''
                            },
                            readBy: [],
                            bidPrice: parseInt(val.bidPrice),
                            priority: val.priority,
                            status: 'added',
                            assignedTo: this.$FirebaseAuth.getUser().uid,
                            addedBy: this.$FirebaseAuth.getUser().email,
                            lastChecked: new Date()
                        });
                        this.$store.dispatch('chat/insert', {
                            message: 'Auto toegevoegd',
                            vehicleId: result,
                            readBy: [],
                            authorUser: {
                                id: 'system',
                                name: 'Systeem'
                            }
                        });
                        return {new: true, id: result};
                    }
                } else {
                    return null;
                }
            }
        },
        async shareVehicle(vehicle) {
            const shareData = {
                title: vehicle.brand + ' ' + vehicle.model,
                text: vehicle.title,
                url: window.location + '/vehicles/' + vehicle.id,
            }

            if (navigator.canShare) {
                // Must be triggered some kind of "user activation"
                try {
                    await navigator.share(shareData)
                } catch (err) {
                    console.log(err)
                }

            }
        },
        async getExistingDocumentId(url) {
            let documents = await this.$FBClient.firestore().collection('vehicles').where('url', '==', url).get()
            if (documents.docs && documents.docs.length) {
                return documents.docs[0].id;
            } else {
                return null;
            }
        }
    }
}