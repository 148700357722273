<template>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="reminder-modalLabel">Reminder toevoegen</h5>
        <button type="button" class="close" data-dismiss="modal" @click="createEmptyReminder()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group datepicker">
          <label for="reminder-date">Datum reminder</label>
          <datepicker
              name="startDate"
              :language="nl"
              id="reminder-date"
              v-model="reminder.date"
              calendar-class="rj-calendar"
              :fullMonthName="true"
              format="dd-MM-yyyy"
              input-class='form-control'
              :calendar-button="true"
              :calendar-button-icon="'far fa-calendar-alt'"
              :disabled-dates="disabledStartDates"
          >
          </datepicker>
        </div>
        <div class="form-group timepicker">
          <label for="reminder-time">Tijdstip reminder</label>
          <div class="reminder-time-container">
            <vue-timepicker
                input-width="100%"
                id="reminder-time"
                auto-scroll
                :format="timeSelector.format" v-model="timeSelector.value"
                :minute-range="getTimeRange.minutes" :hour-range="getTimeRange.hours"></vue-timepicker>
          </div>
        </div>
        <div class="form-group">
          <label for="exampleFormControlTextarea1">Omschrijving:</label>
          <textarea class="form-control" v-model="reminder.message" id="exampleFormControlTextarea1"
                    rows="3"></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" @click="createEmptyReminder()" data-dismiss="modal">
          Close
        </button>
        <button type="button" @click="saveReminder()" :disabled="!reminder.message.length || !timeSelector.value.HH || !timeSelector.value.mm" class="btn btn-secondary">
          Save changes
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {nl} from 'vuejs-datepicker/dist/locale';
import datepicker from 'vuejs-datepicker';
import $ from 'jquery';
import VueTimepicker from 'vue2-timepicker'
import moment from "moment";

export default {
  name: "Reminders",
  props: ['vehicle'],
  components: {
    datepicker,
    VueTimepicker
  },
  data() {
    return {
      nl: nl,
      disabledStartDates: {
        to: new Date()
      },
      timeSelector: {
        format: 'HH:mm',
        value: {
          HH: moment().format('HH'),
          mm: moment().format('mm')
        }
      },
      reminder: {
        message: '',
        date: new Date(),
        assignedTo: null,
        assignedBy: null,
        vehicleId: null
      }
    }
  },
  computed: {
    ...mapGetters(['getUserId']),
    ...mapGetters('users', ['getUserByIdLimited']),
    getTimeRange() {
      if (!moment(this.reminder.date).isSame(moment(), 'date')) {
        return {
          minutes: [[0, 59]],
          hours: [[0, 23]]
        };
      } else {
        const minutes = this.timeSelector.value.HH === moment().format('HH')
            ? moment().format('mm') === '59' ? [] : [[parseInt(moment().format('mm')), 59]]
            : [[0, 59]];
        return {
          minutes: minutes,
          hours: [[parseInt(moment().format('HH')), 23]]
        };
      }
    }
  },
  methods: {
    createEmptyReminder() {
      this.reminder = {
        message: '',
        date: new Date(),
        assignedTo: this.getUserByIdLimited(this.vehicle.assignedTo, ['uid', 'name', 'email']),
        assignedBy: this.getUserByIdLimited(this.getUserId, ['uid', 'name', 'email']),
        vehicleId: this.vehicle.id,
        reminded: false
      };
      this.timeSelector.value.HH = moment().format('HH');
      this.timeSelector.value.mm = moment().format('mm');
    },
    async saveReminder() {
      try {
        this.reminder.date.setHours(parseInt(this.timeSelector.value.HH));
        this.reminder.date.setMinutes(parseInt(this.timeSelector.value.mm));

        await this.$store.dispatch('reminders/insert', this.reminder);
        this.$toasted.global.successRequest({message: 'Reminder opgeslagen'});
        this.createEmptyReminder();
        $('#reminder-modal').modal('hide');
      } catch (e) {
        console.error(e)
        this.$toasted.global.failedRequest();
      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    await this.$store.dispatch('reminders/closeDBChannel', {clearModule: true});
    next()
  },
  created() {
    this.createEmptyReminder();
  },
  watch: {
    'vehicle': {
      deep: true,
      handler() {
        this.createEmptyReminder();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/globalVariables";

h5 {
  color: $rjRed;
}


</style>
<style lang="scss">
@import "../assets/scss/globalVariables";

.datepicker > .vdp-datepicker > div > input {
  padding-left: 4rem !important;
}


.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  border: 1px solid $rjRed !important;
}

.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover {
  border: 1px solid $rjRed !important;
}

.vdp-datepicker__calendar .cell:not(.blank):not(.disabled):not(.disabled).day:hover {
  border: 1px solid $rjRed !important;
}

.selected {
  color: white !important;
}

.active {
  background: $rjRed;
}

.reminder-time-container {
  display: block;
  width: 100%;
}

#reminder-time {
  width: 100%;
  color: #495057;
  background-color: #fff;
  padding: 1rem;
  font-size: 1.5rem;
  line-height: 1.5;
  height: calc(4rem + 2px);
}
.reminder-time-container > span > .controls {
  margin-right: 1rem;
}
</style>