import Vue from 'vue'
import Vuex from 'vuex'
import VuexEasyFirestore from 'vuex-easy-firestore';
import vehicles from './../store/vehicles/vehicles'
import chat from "./chat/chat";
import users from "@/store/users/users";
import reminders from "@/store/reminders/reminders";

const easyFirestore = VuexEasyFirestore(
    [
        vehicles,
        chat,
        reminders,
    ], {
        logging: process.env.NODE_ENV !== 'production',
        FirebaseDependency: Vue.prototype.$FBClient
    }
);
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userId: null,
        userEmail: null
    },
    mutations: {
        setUserId: (state, payload) => {
            state.userId = payload;
        },
        setUserEmail: (state, payload) => {
            state.userEmail = payload;
        }
    },
    getters: {
        getUserId: (state) => {
            return state.userId;
        },
        getUserEmail: (state) => {
            return state.userEmail;
        }
    },
    actions: {},
    modules: { users },
    plugins: [        easyFirestore        ],
    strict: false
});

