<template>
  <div class="details">
    <div class="tab-nav">
      <div @click="setActiveTab('properties')" :active="activeTab === 'properties'" class="tab">
        <i class="fas fa-car"></i>
      </div>
      <div @click="setActiveTab('chat')" :active="activeTab === 'chat'" class="tab">
        <i class="fas fa-comments-alt"></i>
      </div>
      <div @click="setActiveTab('files')" :active="activeTab === 'files'" class="tab">
        <i class="fas fa-file-alt"></i>
      </div>
    </div>

    <div class="duplicate" v-if="isDuplicate">
      Je hebt zojuist een voertuig toegevoegd dat al bestond. Daarom hebben we het voertuig dat je toegevoegd hebt weer verwijderd. Je bekijkt nu het voertuig dat al bestond.
    </div>
    <portlet
        v-if="getSelectedVehicle"
        :title="getTitle"
        :icon="getIcon"
        :actions-block="true"
        :fullheight="false"
        :footer="false"
        class="portlet-pt-10 portlet-red-header"
    >
      <template slot="actionsBlock">
        <i @click="deleteVehicle()" class="delete-button fas fa-trash"></i>
        <VehicleShareIcon :vehicle="getSelectedVehicle"/>
        <i v-if="getUsers.length" data-toggle="modal" data-target="#reminder-modal" class="reminder-button fas fa-bell-plus"></i>
        <i v-else class="reminder-button-disabled fas fa-bell-plus"></i>
      </template>
      <template slot="portletBody">
        <component :key="getSelectedVehicle.id" class="tabb" v-bind:is="activeTab" :vehicle="getSelectedVehicle"></component>
      </template>
    </portlet>
    <div v-if="getUsers.length && getSelectedVehicle" class="modal fade" data-backdrop="static" id="reminder-modal" tabindex="-1" role="dialog" aria-labelledby="reminder-modalLabel" aria-hidden="true">
      <Reminders :vehicle="getSelectedVehicle"></Reminders>
    </div>
    </div>
</template>

<script>

import Properties from "@/components/Properties";
import Files from '@/components/FileManager';
import Chat from '@/components/Chat';
import Reminders from '@/components/Reminders'
import {mapGetters} from "vuex";
import * as _ from 'lodash';

export default {
  name: "Detail",
  components: {
    "properties": Properties,
    "files": Files,
    "chat": Chat,
    Reminders
  },
  mixins:[],
  data() {
    return {
      preventDoubleDelete: false,
      activeTab: 'properties',
      tabs: ['properties', 'chat', 'files']
    }
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    deleteVehicle() {
      this.$swal.fire({
        title: 'Verwijderen',
        html: `Weet je zeker dat je <b>'${this.getSelectedVehicle.title ? this.getSelectedVehicle.title : 'deze auto'}'</b> wil verwijderen? `,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Annuleer',
        confirmButtonText: 'Verwijder'
      }).then(async (result) => {
        if (result.value) {
          this.preventDoubleDelete = true;
          // Delete the vehicle
          await this.$store.dispatch('vehicles/delete', this.getSelectedVehicle.id)
          // close chat channel
          this.$router.back();
        }
      });
    },
    createSwipeEvents() {
      document.addEventListener('swiped-left', () => {
        const currentIndex = this.tabs.indexOf(this.activeTab);
        if (currentIndex + 1 !== this.tabs.length) {
          this.activeTab = this.tabs[currentIndex + 1];
        }
      });
      document.addEventListener('swiped-right', () => {
        const currentIndex = this.tabs.indexOf(this.activeTab);
        if (currentIndex !== 0) {
          this.activeTab = this.tabs[currentIndex - 1];
        }
      });
    },
    findDuplicateUidVehicle(uid) {
      return uid ? _.filter(this.getVehicles, { 'uid': uid }) : [];
    }
  },
  computed: {
    ...mapGetters('vehicles', ['getVehicle', 'getVehicles']),
    ...mapGetters('chat', ['getMessages']),
    ...mapGetters('users', ['getUsers']),
    getSelectedVehicle() {
      return this.getVehicle(this.$route.params.vehicleId);
    },
    isDuplicate() {
      return this.$route.params.duplicate;
    },
    getIcon() {
      if (this.getSelectedVehicle.processing === 'done') {
        return 'this.getSelectedVehicle.title';
      } else if (this.getSelectedVehicle.processing === 'failed') {
        return 'exclamation-triangle'
      } else {
        return 'fas fa-circle-notch fa-spin'
      }
    },
    getTitle() {
      if (this.getSelectedVehicle.processing === 'done') {
        return this.getSelectedVehicle.title;
      } else if (this.getSelectedVehicle.processing === 'failed') {
        return 'Verwerken is mislukt.'
      } else {
        return 'Bezig met verwerken...'
      }
    }
  },
  created() {
    this.createSwipeEvents();
  },
  watch: {
    'getSelectedVehicle': {
      deep: true,
      handler(newV, oldV) {
        const duplicateVehicles = oldV ? this.findDuplicateUidVehicle(oldV.uid) : [];
        if (!newV && !this.preventDoubleDelete && oldV && oldV.processing === 'done') {
          this.$toasted.global.warnRequest({message: 'Iemand heeft het voertuig dat je bekeek verwijderd.'});
          this.$router.push({name: 'home'});
        } else if (!newV && !this.preventDoubleDelete && oldV &&  oldV.processing !== 'done' && duplicateVehicles.length) {
          this.$toasted.global.warnRequest({message: 'Het voertuig dat je net toevoegde is verwijderd, omdat deze al bestond. We hebben de oude geopend.'});
          return this.$router.push({name: 'detail', params: { vehicleId: _.first(duplicateVehicles).id, duplicate: 'yes' }});
        } else if (!newV && !this.preventDoubleDelete && oldV &&  oldV.processing !== 'done') {
          this.$toasted.global.warnRequest({message: 'Het voertuig is verwijderd, omdat het verwerken mislukt is.'});
          this.$router.push({name: 'home'});
        } if(newV && oldV && newV.id && oldV.id && newV.id !== oldV.id) {
          this.activeTab = 'properties';
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/globalVariables";

.tab-nav {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  position: fixed;
  top: 60px;
  background: white;
  width: 100%;
  left: 0;
  height: 5rem;
  align-items: center;
  text-align: center;
  z-index: 99;
}

.tab {
  border-bottom: 1px solid $rjRed;
  color: $rjRed;
  height: 5rem;
  display: grid;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  font-size: 2rem;
}

.tab[active=true] {
  border-bottom: 5px solid $rjRed;
  color: $rjRed;
  height: 5rem;
  display: grid;
  align-items: center;
  cursor: pointer;
}

.details {
  margin-top: calc(5rem);
}

.duplicate {
  border: 1px solid #ff8000;
  background: #ffe5c2;
  padding: 1rem;
  margin-bottom: 2rem;
}

.delete-button {
  color: $rjRed;
  cursor: pointer;
  font-size: 2rem;
}

.reminder-button {
  color: $rjRed;
  cursor: pointer;
  font-size: 2rem;
  margin-right: 2rem;
}

.reminder-button-disabled {
  color: gray;
  cursor: pointer;
  font-size: 2rem;
  margin-right: 2rem;
}
</style>